//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Experience_GoalDonut',

  props: {
    value: { type: Number, default: 0 },
    total: { type: Number, default: 0 },
    content: { type: Object, default: () => ({}) },
  },

  computed: {
    percent() {
      return this.value / this.total * 100;
    },
    offset() {
      return this.percent + 25;
    },
    left() {
      if (this.content.formatter) {
        return this.content.formatter(this.value, this.total);
      }
      return this.value;
    },
    dasharray() {
      return `${this.percent} ${100 - this.percent}`;
    },

    completed() {
      return this.value >= this.total;
    },
  },
};
